<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-07-16 10:47:29
 * @Description: 
-->
<template>
  <div class="app-match">
    <el-card shadow="never">
      <template v-if="playerMsg">
        <el-form label-position="right" label-width="90px" inline class="demo-table-expand">
          <el-form-item label="参赛号码:">
            <span>
              <template v-for="(item,index) in playerMsg" v-key="index">
                {{playerMsg.length>1?item.code+';':item.code}}
              </template>
            </span>
          </el-form-item>
          <el-form-item label="选手姓名:">
            <span>
              <template v-for="(item,index) in playerMsg" v-key="index">
                {{playerMsg.length>1?item.cp.attr.name+';':item.cp.attr.name}}
              </template>
            </span>
          </el-form-item>
          <el-form-item label="项目:">
            <span>{{playerMsg[0].cp.attr.project}}</span>
          </el-form-item>
          <el-form-item label="组别:">
            <span>{{playerMsg[0].cp.attr.group_name}}</span>
          </el-form-item>
          <el-form-item label="参赛赛区:">
            <span>{{playerMsg[0].divide}}</span>
          </el-form-item>
        </el-form>
        <el-avatar shape="square" size="large" :src="avatar"></el-avatar>
      </template>
      <template v-else>
        <i class="el-icon-more empty"> 等待选手上场</i>
      </template>
    </el-card>
    <el-input-number v-model="score" :precision="2" :step="0.01" :min="0" :max="100"></el-input-number>
    <el-slider v-model="score" :step="0.01" :show-tooltip="false"></el-slider>
    <div class="btns">
      <el-button type="primary" icon="el-icon-s-check" @click="setScore">确认成绩</el-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getList, setScore, getScoreState } from "../../api/user";
export default Vue.extend({
  name: "match",
  data() {
    return {
      playerMsg: undefined,
      playerList: [],
      score: 0,
      avatar:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
    };
  },
  created() {
    console.log(1);
    let that = this;
    that.getPlayerList();
    var hAdsTimer = setInterval(function () {
      that.getPlayerList();
    }, 2000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(hAdsTimer);
    });
  },
  methods: {
    getPlayerList() {
      const that = this;
      getList({
        stage_id: this.$store.state.stage_id,
        divide: this.$route.query.divide,
        per_page: 99,
        gaming: 4,
      }).then((res) => {
        // 判断进行中列表有没有选手
        if (res.data.data.data.length != 0) {
          getScoreState({
            token: that.$store.state.token,
            stage_player_id: res.data.data.data[0].id,
          }).then((ress) => {
            // 判断该选手有没有评过分
            if (!ress.data.status && !that.playerMsg) {
              // 没评分
              that.playerMsg = res.data.data.data;
            }
          });
        }
      });
    },
    setScore() {
      let that = this;
      if (!that.playerMsg) {
        this.$message.error("场上选手为空");
        return;
      }
      setScore({
        token: that.$store.state.token,
        stage_player_id: that.playerMsg[0].id,
        score: that.score,
      }).then((res) => {
        if (res.data.status) {
          this.$message.success(res.data.tips);
          that.playerMsg = undefined;
        } else {
          this.$message.error(res.data.tips);
        }
      });
    },
  },
});
</script>
<style lang="sass" >
@import "index.scss"
</style>