<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-07-15 14:29:23
 * @Description: 
-->
<template>
  <router-view class="view main-content" :class="$store.state.isMobile?'mobile':'pc'"></router-view>
</template>
<script>
import Vue from "vue";
import { setCookie, getCookie } from "./utils/cookie";
import { getCompetition } from "./api/user";

export default Vue.extend({
  name: "index",
  data() {
    return {
      token: getCookie("token"),
      admin_type: getCookie("admin_type"),
      date: getCookie("date"),
      divide: getCookie("divide"),
      time: getCookie("time"),
    };
  },
  created() {
    getCompetition({ stage_id: this.$store.state.stage_id }).then((res) => {
      this.$store.commit("setSetting", {
        date: res.data.data.date,
        divide: res.data.data.divide,
        group: res.data.data.group,
        project: res.data.data.project,
        time: res.data.data.time,
        gaming: [
          {
            label: "全部",
            value: undefined,
          },
          {
            label: "未到",
            value: 0,
          },
          {
            label: "弃赛",
            value: 1,
          },
          {
            label: "已到",
            value: 2,
          },
          {
            label: "候场",
            value: 3,
          },
          {
            label: "进行",
            value: 4,
          },
          {
            label: "完赛",
            value: 5,
          },
        ],
      });
    });
    // 登入过
    if (this.token) {
      if (this.$route.path == "/") {
        this.$router.push("/list");
      }
      this.$store.commit("setToken", this.token);
      this.$store.commit("setType", this.admin_type);
      this.$store.commit("setDate", this.date);
      this.$store.commit("setDivide", this.divide);
      this.$store.commit("setTime", this.time);
    }
    this.isMobile();
  },
  methods: {
    isMobile() {
      //获取到userAgent 使用正则表达式解析 若是移动端返回的flag将不为null，反之为null
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.$store.commit("setIsMobile", flag != null);
    }
  },
});
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body,
dl,
dd,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
header,
section,
article,
footer {
  margin: 0;
}

body,
button,
input,
select,
textarea {
  font: 12px/1.5 tahoma, "\5FAE\8F6F\96C5\9ED1", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

em,
b {
  font-style: normal;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

a:hover {
  text-decoration: underline;
}

img {
  border: 0;
}
button,
input,
select,
textarea {
  font-size: 100%;
  outline: none;
  border: 0px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th,
ul,
ol {
  padding: 0;
}
#app,
body,
html,
.main-content {
  width: 100%;
  height: 100%;
}
.main-content {
  overflow: auto;
}
* {
  -webkit-tap-highlight-color: transparent;
}
</style>

