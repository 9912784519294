/*
 * @Author: DingGeGe
 * @Date: 2021-03-30 17:36:13
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-13 10:03:35
 * @Description: 
 */
;!
(function (win, doc) {
    //屏幕适应 
    if (!win.addEventListener) return;
    var html = document.documentElement;
    function setFont() {
        var html = document.documentElement;
        var k = 375;
        if(html.clientWidth > 375){
        html.style.fontSize = "120px";
        }else{
            html.style.fontSize = html.clientWidth / k * 100 + "px";
        }
    }
    setFont();
    setTimeout(function () {
        setFont();
    }, 300);
    doc.addEventListener('DOMContentLoaded', setFont, false);
    win.addEventListener('resize', setFont, false);
    win.addEventListener('load', setFont, false);
})(window, document);