/*
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:50:06
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-06 17:33:53
 * @Description: 
 */
import Vue from 'vue'
import Element from 'element-ui'
import '../element-variables.scss'
Vue.use(Element)
