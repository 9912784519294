<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-06-11 10:42:22
 * @Description: 
-->
<template>
  <div class="app-arrange">
    <div class="player-list">
      <el-table ref="palyerTable" border :data="playerList" :row-class-name="tableRowClassName" @row-click="rowClick" @expand-change="expandChange" :row-key="getRowKeys" :expand-row-keys="rowExpandArray" size="mini" style="width: 100%" height="100%">
        <el-table-column type="expand">
          <template slot="header">
            <div @click="refresh" class="refresh">
              <i class="el-icon-refresh"></i>
            </div>
          </template>
          <template #default="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="参赛赛区">
                <span>{{ props.row.divide}}</span>
              </el-form-item>
              <el-form-item label="参赛组别">
                <span>{{ props.row.cp.attr.group_name}}</span>
              </el-form-item>
              <el-form-item label="参赛日期">
                <span>{{ props.row.date}}</span>
              </el-form-item>
              <el-form-item label="参赛时间">
                <span>{{props.row.time}}</span>
              </el-form-item>
              <el-form-item label="联系电话">
                <a :href="'tel:'+props.row.cp.attr.phone">拨打电话</a>
              </el-form-item>
              <el-form-item>
                <el-radio-group size="mini" v-model="props.row.gaming" @change="setGaming(props)">
                  <el-radio-button label="1">弃赛</el-radio-button>
                  <el-radio-button label="2">已到</el-radio-button>
                  <el-radio-button label="3">候场</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="参赛号码" align="center">
        </el-table-column>
        <el-table-column prop="cp.attr.name" label="姓名" align="center">
        </el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <el-dropdown trigger="click" placement="bottom" @command="handleCommand">
              <span>
                <span ref="lable">
                  赛程
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item,index) in $store.state.setting.gaming" :key="index" :command="index">{{item.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template #default="props">
            {{palyerState(props)}}
          </template>
        </el-table-column>
        <el-table-column prop="cp.attr.project" label="项目类型" align="center">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page-list">
      <el-pagination background layout="total,pager" :total="page.tool" :pager-count="5" :hide-on-single-page="false" @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="[10, 15, 20, 25]" :page-size="page.per_page">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getList, getCompetition, setGaming } from "../../api/user";
import { getCookie, setCookie } from "../../utils/cookie";

export default Vue.extend({
  name: "arrange",
  data() {
    return {
      playerList: [],
      rowExpandArray: [],
      timeList: [],
      selectTime: null,
      page: {
        tool: 0,
        current_page: 1,
        per_page: 20,
      },
      gaming: undefined,
    };
  },
  created() {
    let that = this;
    that.getPlayerList();
    // setInterval(function(){
    //   that.getPlayerList()
    // },2000)
  },
  methods: {
    // 设置参赛状态
    setGaming(val) {
      let that = this;
      setGaming({
        token: this.$store.state.token,
        stage_player_id: val.row.id,
        gaming: val.row.gaming,
      }).then((res) => {
        if (res.data.status) {
          this.$message({
            type: "success",
            message: res.data.tips,
            duration: 300,
          });
          that.getPlayerList();
        } else {
          this.$message({
            type: "error",
            message: res.data.tips,
            duration: 300,
          });
          setCookie("token", "", -1);
          setCookie("admin_type", "", -1);
          setCookie("date", "", -1);
          setCookie("time", "", -1);
          setCookie("divide", "", -1);
          this.$router.push("/");
          this.$message.success("退出账号成功");
        }
      });
    },
    refresh() {
      this.getPlayerList();
      this.rowExpandArray = [];
      this.$message({
        type: "success",
        message: "数据已更新",
        duration: 300,
      });
    },
    // 获取选手列表
    getPlayerList() {
      const that = this;
      getList({
        stage_id: this.$store.state.stage_id,
        date: this.$store.state.date,
        divide: this.$route.query.divide,
        time: this.$store.state.time,
        gaming: this.gaming,
        current_page: this.page.current_page,
        per_page: this.page.per_page,
      }).then((res) => {
        that.page.tool = res.data.data.total;
        that.playerList = res.data.data.data;
      });
    },
    //分页筛选
    handleCurrentChange(val) {
      this.page.current_page = val;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    handleSizeChange(val) {
      this.page.per_page = val;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    // 赛程筛选
    handleCommand(props) {
      const item = this.$store.state.setting.gaming[props];
      this.gaming = item.value;
      this.$refs.lable.innerText = item.label;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    // 选手状态
    palyerState(props) {
      const str = this.$store.state.setting.gaming.find(
        (obj) => obj.value == props.row.gaming
      ).label;
      return str;
    },
    // 签到状态
    tableRowClassName(row) {
      if (row.row.gaming == 1) {
        return "error-row";
      } else if (row.row.sign_in === 1) {
        return "success-row";
      }
    },
    // 点击折叠行
    rowClick(row) {
      console.log(row);
      if (this.rowExpandArray.indexOf(row.id) != -1) {
        this.rowExpandArray.splice(this.rowExpandArray.indexOf(row.id), 1);
      } else {
        this.rowExpandArray.push(row.id);
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    expandChange(expanded, expandedRows) {
      if (this.rowExpandArray.indexOf(expanded.id) != -1) {
        this.rowExpandArray.splice(this.rowExpandArray.indexOf(expanded.id), 1);
      } else {
        this.rowExpandArray.push(expanded.id);
      }
    },
  },
});
</script>
<style lang="sass">
@import "index.scss"
</style>