/*
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-12 14:46:39
 * @Description: 
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'',
    isMobile:'',
    admin_type:'',
    setting:{
      gaming:[
        {
          label: "全部",
          value: undefined,
        },
        {
          label: "未到",
          value: 0,
        },
        {
          label: "弃赛",
          value: 1,
        },
        {
          label: "已到",
          value: 2,
        },
        {
          label: "候场",
          value: 3,
        },
        {
          label: "进行",
          value: 4,
        },
        {
          label: "完赛",
          value: 5,
        },
      ]
    },
    c_id:undefined,
    stage_id:undefined,
    date:undefined,
    divide:undefined,
    time:undefined
  },
  mutations: {
    setToken(state: any, token: any) {
      state.token = token
    },
    setSetting(state: any, setting: any) {
      state.setting = setting
    },
    setIsMobile(state: any, isMobile: any) {
      state.isMobile = isMobile
    },
    setType(state: any, admin_type: any) {
      state.admin_type = admin_type
    },
    setDate(state: any, date: any) {
      state.date = date
    },
    setTime(state: any, time: any) {
      state.time = time
    },
    setDivide(state: any, divide: any) {
      state.divide = divide
    },
    setStage_id(state: any, stage_id: any) {
      state.stage_id = stage_id
    },
    setC_id(state: any, c_id: any) {
      state.c_id = c_id
    },
  },
  actions: {
  }
})
