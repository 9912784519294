/*
 * @Author: DingGeGe
 * @Date: 2021-03-30 14:10:09
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-12 16:15:47
 * @Description: 
 */
export const getCookie = (name: string) => {
    const arr = document.cookie.split("; ");//由于cookie是通过一个分号+空格的形式串联起来的，所以这里需要先按分号空格截断,变成[name=Jack,pwd=123456,age=22]数组类型；
    for (var i = 0; i < arr.length; i++) {
        const item = arr[i].split("=");//通过=截断，把name=Jack截断成[name,Jack]数组；
        if (item[0] == name) {
            return decodeURI(item[1]);
        }
    }
}
export const setCookie = (name: string, val: string,exdays:number) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie =name + "=" + encodeURI(val) + "; " + expires
    return true
}