/*
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-14 15:25:03
 * @Description: 
 */
import Vue from 'vue'
import store from '../store'
import VueRouter, { RouteConfig } from 'vue-router'
import Index from '../views/index/index.vue'
import Login from '../views/login/index.vue'
import Arrange from '../views/arrange/index.vue'
import Match from '../views/match/index.vue'
import List from '../views/list/index.vue'
import Judge from '../views/judge/index.vue'
import { setCookie,getCookie } from '@/utils/cookie'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/judge',
        name: 'Judge',
        component: Judge
      },
      {
        path: '/arrange',
        name: 'Arrange',
        component: Arrange
      },
      {
        path: '/match',
        name: 'Match',
        component: Match
      },
      {
        path: '/list',
        name: 'List',
        component: List
      },
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from,next) => {
  if(!store.state.c_id){
    store.commit("setC_id", to.query.c_id);
    store.commit("setStage_id", to.query.s_id);
  }
  if(to.name=='Arrange'){
    document.title='场控端-'+to.query.divide
  }else{
    document.title='赛事系统'
  }
  const token = getCookie('token')
  if(to.name !== 'Login' && !token) {
    next('/')
  }else{
    next()
  }
})

export default router 
