<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-07-15 11:09:59
 * @Description: 
-->
<template>
  <div class="app-list">
    <el-card shadow="hover" v-for="item in divide" :key="item">
      <div @click="onMainPage(item)">
        {{item}}
      </div>
    </el-card>
  </div>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  name: "index",
  data() {
    return {
      divide: this.$store.state.setting.divide,
    };
  },
  created() {
    let that = this;
    var hAdsTimer = setInterval(function () {
      if (that.$store.state.setting.divide != undefined) {
        that.divide = that.$store.state.setting.divide;
        clearInterval(hAdsTimer);
      }
    }, 10);
  },
  methods: {
    onMainPage(val) {
      let that=this
      console.log(val, this.$store.state.admin_type);
      switch (Number(this.$store.state.admin_type)) {
        case 0:
          this.$router.push({
            path: "/arrange",
            query: {
              c_id: that.$store.state.c_id,
              s_id: that.$store.state.stage_id,
              divide: val,
            },
          });
          return;
        case 1:
          this.$router.push({
            path: "/match",
            query: {
              c_id: that.$store.state.c_id,
              s_id: that.$store.state.stage_id,
              divide: val,
            },
          });
          return;
        case 2:
          console.log(1);
          this.$router.push({
            path: "/judge",
            query: {
              c_id: that.$store.state.c_id,
              s_id: that.$store.state.stage_id,
              divide: val,
            },
          });
          return;
      }
    },
  },
});
</script>
<style lang="sass">
@import "index.scss"
</style>