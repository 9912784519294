<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-07-15 17:04:10
 * @Description: 
-->
<template>
  <div class="app-match">
    <div class="player-list">
      <el-divider content-position="left">场上选手</el-divider>
      <div class="palyerTable_in">
        <el-table ref="palyerTable_in" border :data="playerList_in" size="mini" style="width: 100%">
          <el-table-column prop="code" label="参赛号码" align="center">
          </el-table-column>
          <el-table-column prop="cp.attr.name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="cp.attr.project" label="项目类型" align="center">
          </el-table-column>
          <el-table-column label="MP3" align="center">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.cp.attr.mp3" @click="onMusic(scope.row)" type="primary">播放音乐</el-button>
              <el-tag type="danger" size="medium  " v-else>未上传</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="setGaming(scope.row.id,3)" type="danger">下场</el-button>
              <el-button size="mini" @click="setGaming(scope.row.id,5)" type="primary">完赛</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-divider content-position="left">候场选手</el-divider>
      <div class="palyerTable">
        <el-table ref="palyerTable" border :data="playerList" size="mini" style="width: 100%" height="100%">
          <el-table-column prop="code" label="参赛号码" align="center">
          </el-table-column>
          <el-table-column prop="cp.attr.name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="cp.attr.project" label="项目类型" align="center">
          </el-table-column>
          <el-table-column label="MP3" align="center">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.cp.attr.mp3" @click="onMusic(scope.row)" type="primary">播放音乐</el-button>
              <el-tag type="danger" size="medium  " v-else>未上传</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="setGaming(scope.row.id,4)" type="primary">上场</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getList, setGaming } from "../../api/user";
export default Vue.extend({
  name: "match",
  data() {
    return {
      playerList: [],
      playerList_in: [],
      rowExpandArray: [],
      timeList: [],
      selectTime: null,
      page: {
        tool: 0,
        current_page: 1,
        per_page: 15,
      },
      gaming: undefined,
    };
  },
  created() {
    let that = this;
    that.getPlayerList();
    var hAdsTimer = setInterval(function () {
      that.getPlayerList();
    }, 2000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(hAdsTimer);
    });
  },
  methods: {
    // 设置参赛状态
    setGaming(id, type) {
      let that = this;
      if (type == 4 && this.playerList_in.length > 0) {
        this.$message.error("场上还有正在比赛的选手，请先点击完赛");
        return;
      }
      if(type==5){
        this.$confirm(
        type == 4
          ? "是否确定将此选手开始比赛"
          : type == 5
          ? "确认选手表演完成后点击确认"
          : "点击下场评委老师将看不见此选手, 是否继续",
        "操作",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          setGaming({
            token: that.$store.state.token,
            stage_player_id: id,
            gaming: type,
          }).then((res) => {
            if (res.data.status) {
              this.$message.success(res.data.tips);
              that.getPlayerList();
            } else {
              this.$message.error(res.data.tips);
            }
          });
        })
        .catch((res) => {});
      }else{
         setGaming({
            token: that.$store.state.token,
            stage_player_id: id,
            gaming: type,
          }).then((res) => {
            if (res.data.status) {
              this.$message.success(res.data.tips);
              that.getPlayerList();
            } else {
              this.$message.error(res.data.tips);
            }
          });
      }
      console.log(type)
      
    },
    onMusic(row) {
      const mp3 = row.cp.attr.mp3;
      if (!mp3) {
        this.$message.error("此选手没有上传mp3");
        return;
      }
      window.open(mp3);
    },
    refresh() {
      this.getPlayerList();
      this.rowExpandArray = [];
      this.$message.success("数据已更新");
    },
    // 获取选手列表
    getPlayerList() {
      const that = this;
      getList({
        stage_id: this.$store.state.stage_id,
        divide: this.$route.query.divide,
        per_page: 99,
        gaming: 3,
      }).then((res) => {
        that.playerList = res.data.data.data;
      });
      getList({
        stage_id: this.$store.state.stage_id,
        divide: this.$route.query.divide,
        per_page: 99,
        gaming: 4,
      }).then((res) => {
        that.playerList_in = res.data.data.data;
      });
    },
    //分页筛选
    handleCurrentChange(val) {
      this.page.current_page = val;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    handleSizeChange(val) {
      this.page.per_page = val;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    // 赛程筛选
    handleCommand(props) {
      const item = this.$store.state.setting.gaming[props];
      this.gaming = item.value;
      this.$refs.lable.innerText = item.label;
      this.getPlayerList();
      this.rowExpandArray = [];
    },
    // 选手状态
    palyerState(props) {
      const str = this.$store.state.setting.gaming.find(
        (obj) => obj.value == props.row.gaming
      ).label;
      return str;
    },
    // 签到状态
    tableRowClassName(row) {
      if (row.row.sign_in === 1) {
        return "success-row";
      } else {
        return "error-row";
      }
    },
    // 点击折叠行
    rowClick(row) {
      console.log(row);
      if (this.rowExpandArray.indexOf(row.id) != -1) {
        this.rowExpandArray.splice(this.rowExpandArray.indexOf(row.id), 1);
      } else {
        this.rowExpandArray.push(row.id);
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    expandChange(expanded, expandedRows) {
      if (this.rowExpandArray.indexOf(expanded.id) != -1) {
        this.rowExpandArray.splice(this.rowExpandArray.indexOf(expanded.id), 1);
      } else {
        this.rowExpandArray.push(expanded.id);
      }
    },
  },
});
</script>
<style lang="sass">
@import "index.scss"
</style>