/*
 * @Author: DingGeGe
 * @Date: 2021-04-06 16:27:35
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-06-10 18:22:15
 * @Description: 
 */
import axios from 'axios'
const http = 'https://www.dongtingchina.com'
export const login = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Admin/login',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
export const setGaming = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Admin/setGaming',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
export const setScore = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Admin/setScore',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
export const getScoreState = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Admin/getScoreState',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
export const getList = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Stage_Player/getList',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
export const getCompetition = (params: any) => {
    return new Promise(function (success, error) {
        axios({
            method: 'post',
            url: http + '/api/Mod_Competition_Stage/getData',
            data: params
        }).then((res) => {
            success(res)
        }).catch((res) => {
            error(res)
        });
    })
}
