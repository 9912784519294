<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-04-14 15:24:51
 * @Description: 
-->
<template>
  <div class="app-index">
    <el-row :gutter="24" class="header">
      <el-col :span="24">
        <div class="top">
          <div class="page">
          <el-page-header @back="onPage('/list')" :content="'评委端—'+$route.query.divide"  v-if="$route.path=='/judge'">
          </el-page-header>
           <el-page-header @back="onPage('/list')" :content="'音控端—'+$route.query.divide" v-else-if="$route.path=='/match'">
          </el-page-header>
          </div>
          <div class="right">
            <el-select placeholder="请选择赛事日期" size="mini" v-model="date" @change="onChangeDate" v-if="$route.path=='/arrange'">
              <el-option label="所有日期" :value="undefined" :key="undefined"></el-option>
              <el-option :label="item" :value="item" v-for="(item,index) in $store.state.setting.date" :key="index"></el-option>
            </el-select>
            <el-select v-model="time" placeholder="请选择时间段" size="mini" @change="onChangeTime" v-if="$route.path=='/arrange'">
              <el-option :key="undefined" label="全部时段" :value="undefined">
              </el-option>
              <el-option v-for="item in $store.state.setting.time" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-button type="danger" size="mini" @click="onReturn">退出</el-button>
            <!-- <div @click="dialogVisible = true" class="setting">
              <el-image fit="contain" src="//meflourish.oss-cn-zhangjiakou.aliyuncs.com/static/admin-ts/images/icon_exit.png"></el-image>
            </div> -->
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="content">
      <!-- <el-col :xs="0" :sm="4">
        <div class="aside">
          <el-menu :default-active="$route.path" :router="true" class="el-menu-vertical-demo">
            <el-menu-item :index="item.path" v-for="(item,index) in menuList" v-bind:key="index">
              <i class="el-icon-menu"></i>
              <span :title="item.title">{{item.title}}</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col> -->
      <el-col :xs="24" :sm="24">
        <div class="main">
          <router-view ref="childRouter"></router-view>
        </div>
      </el-col>
    </el-row>
    <!-- <el-dialog title="设置" :visible.sync="dialogVisible">
      <el-form @submit.native.prevent>
        <el-form-item label="日期设置">
          <el-select placeholder="请选择赛事日期" size="mini" v-model="date" @change="onChangeDate">
            <el-option label="所有日期" :value="undefined" :key="undefined"></el-option>
            <el-option :label="item" :value="item" v-for="(item,index) in $store.state.setting.date" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场地设置">
          <el-select placeholder="请选择赛场" size="mini" v-model="divide" @change="onChangeDivide">
            <el-option label="全部赛场" :value="undefined" :key="undefined"></el-option>
            <el-option :label="item" :value="item" v-for="(item,index) in $store.state.setting.divide" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="onReturn" size="small">退出账号</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
  </div>
</template>
<script>
import Vue from "vue";
import { getCookie, setCookie } from "../../utils/cookie";
export default Vue.extend({
  name: "index",
  data() {
    return {
      menuList: [
        {
          title: "评委管理",
          path: "/judge",
        },
        {
          title: "催场管理",
          path: "/arrange",
        },
        {
          title: "场控管理",
          path: "/match",
        },
        {
          title: "签到管理",
          path: "/sign",
        },
      ],
      dialogVisible: false,
      date:
        this.$store.state.date == "undefined"
          ? undefined
          : this.$store.state.date,
      divide:
        this.$store.state.divide == "undefined"
          ? undefined
          : this.$store.state.divide,
      time:
        this.$store.state.time == "undefined"
          ? undefined
          : this.$store.state.time,
    };
  },
  created() {},
  methods: {
    onPage(path) {
      let that=this
      this.$router.push({
            path: "/list",
            query:{
              c_id:that.$store.state.c_id,
              s_id:that.$store.state.stage_id,
            }
          });
    },
    onDialog() {
      this.dialogVisible = true;
    },
    onChangeDate(val) {
      this.$store.commit("setDate", val);
      setCookie("date", val, 30);
      this.$refs.childRouter.getPlayerList();
    },
    onChangeTime(val) {
      this.$store.commit("setTime", val);
      setCookie("time", val, 30);
      this.$refs.childRouter.getPlayerList();
    },
    onChangeDivide(val) {
      this.$store.commit("setDivide", val);
      setCookie("divide", val, 30);
    },
    onReturn() {
      let that=this

      setCookie("token", "", -1);
      setCookie("admin_type", "", -1);
      setCookie("date", "", -1);
      setCookie("time", "", -1);
      setCookie("divide", "", -1);
      this.$router.push({
            path: "/",
            query:{
              c_id:that.$store.state.c_id,
              s_id:that.$store.state.stage_id,
            }
          });
      this.$message.success("退出账号成功");
    },
  },
});
</script>
<style lang="sass">
@import "index.scss"
</style>