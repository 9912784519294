<!--
 * @Author: DingGeGe
 * @Date: 2021-03-29 14:42:54
 * @LastEditors: DingGeGe
 * @LastEditTime: 2021-05-20 14:05:31
 * @Description: s
-->
<template>
  <div class="app-index">
    <el-form ref="form" :model="form" size="medium" v-loading="loading">
    <div class="title">赛事系统</div>
      <el-form-item label="账户" prop="account">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="passWord">
        <el-input type="password" v-model="form.passWord" autocomplete="off" show-password></el-input>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onLogin">登入</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>
<script>
import Vue from "vue";
import { setCookie, getCookie } from "../../utils/cookie";
import { login } from "../../api/user";
export default Vue.extend({
  name: "index",
  data() {
    return {
      form: {
        name: "",
        passWord: "",
      },
      loading: false,
    };
  },
  created() {
    this.setDate();
  },
  methods: {
    setDate() {
      var myDate = new Date();
      var date =
        myDate.getFullYear() +
        "-" +
        (myDate.getMonth() + 1) +
        "-" +
        myDate.getDate();
      this.$store.commit("setDate", date);
      setCookie("date", date, 30);
    },
    onLogin() {
      const that = this;
      if (this.form.name == "") {
        this.$message.error("账号不能为空");
        return;
      }
      if (this.form.passWord == "") {
        this.$message.error("密码不能为空");
        return;
      }
      this.loading = true;
      login({
        c_id: this.$store.state.c_id,
        name: this.form.name,
        password: this.form.passWord,
      }).then((res) => {
        if (res.data.status) {
          setCookie("token", res.data.data.token, 30);
          setCookie("admin_type", res.data.data.admin_type, 30);
          this.$store.commit("setToken", res.data.data.token);
          this.$store.commit("setType", res.data.data.admin_type);
          this.$router.push({
            path: "/list",
            query:{
              c_id:that.$store.state.c_id,
              s_id:that.$store.state.stage_id,
            }
          });
        } else {
          that.loading = false;
          this.$message.error(res.data.tips);
        }
      });
    },
  },
});
</script>
<style lang="sass" scoped>
@import "index.scss"
</style>